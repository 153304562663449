import React, { Fragment } from "react";
import { useTranslation } from "@onefront/react-sdk";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Modal from "@vapor/react-material/Modal";
import Divider from "@vapor/react-material/Divider";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Style
import { modalStyle } from "../businessLogic/styles";

export const ModalDelete = ({ show, onClose, onOk }) => {
  const { t } = useTranslation();
  //const [isLoading, setIsLoading] = useState(false);

  //Actions
  const handleModalClose = (e) => {
    onClose(e);
  };
  const handleModalOk = (e) => {
    onOk(e);
  };

  return (
    <Fragment>
      <Modal
        key="deleteModal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={show}
        onClose={handleModalClose}
        onBackdropClick={handleModalClose}
      >
        <Box sx={modalStyle}>
          <Stack
            direction="column"
            spacing={0}
            justifyContent="left"
            alignItems="left"
          >
            <ExtendedTypography
              variant="titleSmall"
              color="primary.main"
              sx={{ p: 2, pl: 3 }}
            >
              {t("title.deleteConfirm")}
            </ExtendedTypography>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ bgcolor: "#D82829 !important" }}
            />
            {/* <ExtendedTypography variant="subtitle2" sx={{ p:2, pl: 3, pt: 3 }}>{t("message.areYouSureToDelete")}</ExtendedTypography> */}
            <ExtendedTypography variant="subtitle2" sx={{ p: 2, pl: 3, pt: 3 }}>
              {t("modal.deleteDesc")}
            </ExtendedTypography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, mt: 1, bgcolor: "#F2F5F8" }}
            justifyContent="right"
            alignItems="right"
          >
            <Button variant="outlined" size="small" onClick={handleModalClose}>
              {t("actions.annulla")}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={handleModalOk}
            >
              {t("actions.positiveDelete")}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  );
};

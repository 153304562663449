let instance;
let globalState = {
    tenantId: "",
    tenantModel: null,
    aziendaId: 0,
    aziendaModelList: null,
    userId: "",
    userModel: null,
    userPermissions: null,
    version: ""
};

class SharedDatas {
    constructor() {
        if (instance) {
            throw new Error("New instance cannot be created!");
        }

        instance = this;
    }

    getPropertyByName(propertyName) {
        return globalState[propertyName];
    }

    setPropertyValue(propertyName, propertyValue) {
        globalState[propertyName] = propertyValue;
    }
}

let sharedDatasInstance = Object.freeze(new SharedDatas());

export default sharedDatasInstance;
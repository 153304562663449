import React, { useState, useEffect } from "react";
import sharedDatas from "../../../businessLogic/sharedDatas";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar,useQuery,useMutation,useTranslation } from "@onefront/react-sdk"
import { DataGridPro, GridToolbar, itIT, LicenseInfo } from "@mui/x-data-grid-pro";
import { pathReleaseNotesHome } from "../../../businessLogic/constants"
import { userPermissionModel } from "../../../businessLogic/models";
import { query_releaseNotes } from "../../../businessLogic/query";
import { releaseNotesModel } from "../../../businessLogic/models";
import { 
    mutation_add_log,
    mutation_add_audit,
    mutation_add_lastActivity_byUserId,
    mutation_delete_lastActivity
} from "../../../businessLogic/mutation";
import { bsaSettingsReleaseNotes } from "../../../businessLogic/constants"

//Vapor components
import VaporPage from "@vapor/react-custom/VaporPage";
import Grid from "@vapor/react-material/Grid";
import Button from "@vapor/react-material/Button";

//Custom components
import { Loading } from "../../../components/Loading";
import { NotAuth } from "../../../components/NotAuth";
import { NoData } from "../../../components/NoData";

export const ReleaseNotesHome = ({basePath}) =>{
    
    //#region Mutation
    const apiAddLog = useMutation(mutation_add_log, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const apiAddAudit = useMutation(mutation_add_audit, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    //#endregion

    //#region query
    const apiGetReleaseNotes = useQuery(
        query_releaseNotes,
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql"}
    );
    //#endregion

    //#region Variable declarations
    const tenantId = sharedDatas.getPropertyByName("tenantId");
    const aziendaId = sharedDatas.getPropertyByName("aziendaId");
    const loggedUserId = sharedDatas.getPropertyByName("userId");
    const tenantModel = sharedDatas.getPropertyByName("tenantModel");
    const aziendaModelList = sharedDatas.getPropertyByName("aziendaModelList");
    const userModel = sharedDatas.getPropertyByName("userModel");
    const sharedUserPermissions = sharedDatas.getPropertyByName("userPermissions")
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    //#endregion

    //#region UseState
    const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
    const[isDataLoaded,setIsDataLoaded] = useState(false) //Indica lo stato di caricamento del result Api
    const [isTenantEnabled, setIsTenantEnabled] = useState(false);
    const [userPermissions, setUserPermissions] = useState(
        new userPermissionModel(
            0,
            aziendaId,
            bsaSettingsReleaseNotes,
            false,
            false,
            false,
            false
        )
    );
    const [dataRows,setDataRows] = useState([]);
    const [dataColumns,setDataColumns] = useState([]);
    //#endregion

    //#region functions
    useEffect(()=>{
        loadDatas();
    },[isDataLoaded])

    const loadDatas = async () => {
        try{
            LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY);
            
            if(
                tenantId === "" || 
                aziendaId === 0 ||
                loggedUserId === "" ||
                tenantModel === null ||
                aziendaModelList === null ||
                userModel === null
            ){
                navigate("/")
            }
            else {
                setBackDropOpen(true);
                if(!isDataLoaded){
                    //Last Activity
                    await apiDeleteLastActivity.fetch({
                        tenantId: tenantId,
                        aziendaId: aziendaId,
                        userId: loggedUserId,
                    });
                    await apiAddLastActivity.fetch({
                        tenantId: tenantId,
                        aziendaId: aziendaId,
                        path: pathReleaseNotesHome,
                        entityId: "",
                        entityName: "",
                    });
                }

                /**************************************/
                //Carico le informazioni relative al Tenant
                const isEnabled = !tenantModel.isReadOnly && !tenantModel.isDisabled && !tenantModel.isDeleted;
                setIsTenantEnabled(isEnabled);

                /**************************************/
                //Carico i permessi relativi all'utente
                var perms = sharedUserPermissions.filter((x) => x.area === bsaSettingsReleaseNotes)[0];
                setUserPermissions(perms);                

                /**************************************/
                //Carico le release notes
                debugger;
                var releaseNotes = await apiGetReleaseNotes.fetch()     
                const arrItems = [];
                for (let i = 0; i < releaseNotes.data.data.releasenotes.length; i++) {
                    const item = releaseNotes.data.data.releasenotes[i]
                    const itemModel = new releaseNotesModel(
                        item.Id,
                        item.Version,
                        item.Date,
                        item.Pdf
                    );
                    arrItems.push(itemModel)
                }
                setDataRows(arrItems);

                /**************************************/
                //Carico le release notes
                debugger
                setDataColumns([
                    {field:"id",headerName:"Id",type:"number",flex:0.2},
                    {field:"version",headerName:"Versione",type:"string",flex:0.2},
                    {field:"date",headerName:"Data",type:"dateTime",flex:0.2,
                        valueFormatter: (params) => {
                            if (params.value == null) {
                                return "";
                            }
                            return new Date(params.value).toLocaleDateString();
                            },
                    },
                    {field:"pdf",headerName:"Pdf",type:"string",flex:0.2,
                        renderCell: (params) => (
                            <a href={"/releaseNotes/"+params.value} download={params.value}>{params.value}</a>
                          )
                    }
                ])
            }
        } catch (e) {
            apiAddLog.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                level: "ERROR",
                message: e.message,
                stack: e.stack,
                area: "ReleaseNoteHome",
                method: "loadDatas",
                version: sharedDatas.getPropertyByName("version"),
              });
              enqueueSnackbar(t("error.generale"), { variant: "error" });
        } finally {
            setBackDropOpen(false);
            setIsDataLoaded(true);
        }
    }
    //#endregion

    //#region Graphic
    return(
        <VaporPage title="Note di rilascio">
            <Loading open={backDropOpen}/>
            {isDataLoaded && !userPermissions.canRead ? <NotAuth/> : null}

            {isDataLoaded && userPermissions.canRead ? (
                <VaporPage.Section>
                    <Grid container>
                        <Grid item xs={12} sx={{ width: "100%" }}>              
                        {dataRows.length > 0 ? (
                            <DataGridPro
                            rows={dataRows}
                            columns={dataColumns}
                            slots={{ toolbar: GridToolbar }}
                            autoHeight={true}
                            pageSizeOptions={[10, 20, 50]}
                            localeText={
                                itIT.components.MuiDataGrid.defaultProps.localeText
                            }
                            pagination
                            disableRowSelectionOnClick                            
                            initialState={{
                                columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                                },
                                pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                                },
                            }}
                            slotProps={{
                                toolbar: { printOptions: { disableToolbarButton: true } },
                            }}
                            />
                        ) : (
                            <NoData />
                        )}
                        </Grid>
                    </Grid>
                </VaporPage.Section>
            ) : null}
        </VaporPage>
    )
    //#endregion
}
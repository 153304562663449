import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import IconButton from "@vapor/react-material/IconButton";
import Stack from "@vapor/react-material/Stack";

import {
  LicenseInfo,
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Icons
import {
  ArrowForward as DetailIcon,
  ArrowForward as ArrowForwardIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";

//Constants, Api and Data Models
import { bsaRischio, pathRischioHome } from "../../businessLogic/constants";
import {
  setLocalStorageSelectedFascicolo,
  setLocalStorageSelectedEntity,
} from "../../businessLogic/bl";
import { userPermissionModel, userModel } from "../../businessLogic/models";
import {
  query_rischi_byTenant,
  query_users_byCompanyId,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";

export const RischioHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischiByTenant = useQuery(
    query_rischi_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaRischio,
      false,
      false,
      false,
      false
    )
  );

  const [utentiList, setUtentiList] = useState([]);
  const [rischiList, setRischiList] = useState([]); //Contiene le valutazioni

  //DataGrid
  const [dataItems, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows
  const [filteredRows, setFilteredRows] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathRischioHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaRischio)[0];
          setUserPermissions(userPerms);

          /**************************************/
          //Carico gli utenti
          const usersResponse = await apiUsersByCompanyId.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          const arrUsers = [];
          usersResponse.data.data.user_tenant.map((item) =>
            arrUsers.push(
              new userModel(
                item.UserId,
                item.TenantId,
                item.user.Username,
                item.user.Email,
                item.user.Name,
                item.user.Surname,
                item.user.Name + " " + item.user.Surname,
                item.Type,
                item.user.Locale,
                item.TenantTipoId,
                item.user.IsRespAntiRic,
                item.base_tipo_tenant.Value,
                item.user.IsActive,
                item.user.IsDeleted,
                item.user.IsBanned,
                item.user.CreatedOn,
                item.user.Cf
              )
            )
          );
          setUtentiList(arrUsers);

          /**************************************/
          //Carico i rischi
          setDataColumns([
            { field: "id", headerName: "Id", flex: 0.2, type: "number" },
            {
              field: "fascicoloId",
              headerName: "Id Fascicolo",
              type: "number",
              flex: 0.2,
            },
            {
              field: "fascicoloNome",
              headerName: "Fascicolo",
              flex: 0.3,
              editable: false,
            },
            {
              field: "prestazioneNome",
              headerName: "Prestazione",
              flex: 0.3,
              editable: false,
            },
            {
              field: "prestazioneData",
              headerName: "Data",
              type: "date",
              flex: 0.3,
              editable: false,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            { field: "scopo", headerName: "Scopo", flex: 0.3, editable: false },
            {
              field: "natura",
              headerName: "Natura",
              flex: 0.3,
              editable: false,
            },
            {
              field: "rischi",
              headerName: "N. Rischi",
              flex: 0.3,
              type: "number",
              editable: false,
            },
            {
              field: "inerente",
              headerName: "Inerente",
              flex: 0.3,
              editable: false,
            },
            {
              field: "specifico",
              headerName: "Specifico",
              flex: 0.3,
              editable: false,
            },
            {
              field: "effettivo",
              headerName: "Effettivo",
              flex: 0.3,
              editable: false,
              renderCell: (params) => renderLivelloRischio(params.row),
              valueGetter: (params) => renderLivelloRischioS(params.row),
            },
            {
              field: "adeguataVerifica",
              headerName: "Adeguata verifica",
              flex: 0.5,
              editable: false,
              renderCell: (params) => renderAdeguataVerifica(params.row),
              valueGetter: (params) => renderAdeguataVerificaS(params.row),
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(userPerms, params.row),
            },
          ]);

          const rischiResponse = await apiRischiByTenant.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          const arrItems = [];

          for (let i = 0; i < rischiResponse.data.data.rischio.length; i++) {
            const item = rischiResponse.data.data.rischio[i];

            if (
              arrItems.filter((x) => x.id === item.PrestazioneId).length <= 0
            ) {
              const filteredResponse = rischiResponse.data.data.rischio.filter(
                (x) => x.PrestazioneId === item.PrestazioneId
              );

              const rischiArr = [];
              for (let m = 0; m < filteredResponse.length; m++) {
                const rischio = filteredResponse[m];

                const rischioModel = {
                  id: item.Id,
                  inerente: rischio.EfRiTotale,
                  specifico: rischio.EfSpTotale,
                  effettivo: rischio.EfTotale,
                  adeguataVerifica: rischio.EfAdeguataLivello,
                  data: rischio.Date,
                  prestazioneNome:
                    rischio.prestazione.base_tipo_prestazione.Nome,
                  professionista:
                    arrUsers.filter((x) => x.userId === rischio.ProvidedBy)[0]
                      .name +
                    " " +
                    arrUsers.filter((x) => x.userId === rischio.ProvidedBy)[0]
                      .surname,
                };
                rischiArr.push(rischioModel);
              }

              for (let f = 0; f < rischiArr.length; f++) {
                const itemModel = {
                  path: [
                    item.prestazione.fascicolo.Name,
                    rischiArr[f].prestazioneNome,
                    rischiArr[f].professionista,
                    new Date(rischiArr[f].data).toLocaleDateString(),
                  ],
                  pathId: uuidv4(),
                  id: item.PrestazioneId,
                  fascicoloId: item.prestazione.fascicolo.Id,
                  fascicoloNome: item.prestazione.fascicolo.Name,
                  prestazioneNome: item.prestazione.base_tipo_prestazione.Nome,
                  prestazioneData: item.prestazione.StartDate,
                  scopo: item.prestazione.base_scopo_prestazione.Value,
                  natura: item.prestazione.base_natura_prestazione.Value,
                  rischi: rischiArr.length,
                  rischioId: rischiArr[f].id,
                  inerente: rischiArr[f].inerente,
                  specifico: rischiArr[f].specifico,
                  effettivo: rischiArr[f].effettivo,
                  adeguataVerifica: rischiArr[f].adeguataVerifica,
                };
                arrItems.push(itemModel);
              }
            }
          }

          setRischiList(arrItems);
          setDataItems(arrItems);
          setDataRows(arrItems);
          setFilteredRows(arrItems);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "RischioHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //DataGrid
  //Grid Button Detail
  const renderLivelloRischio = (item) => {
    if (item.effettivo !== undefined) {
      let livelloS = t("valutazione.form.rischioInerenteLivello1");
      let livelloC = chipGreen;

      if (item.effettivo >= 1 && item.effettivo <= 1.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello1");
        livelloC = chipGreen;
      } else if (item.effettivo > 1.5 && item.effettivo <= 2.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello2");
        livelloC = chipYellow;
      } else if (item.effettivo > 2.5 && item.effettivo <= 3.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello3");
        livelloC = chipOrange;
      } else if (item.effettivo > 3.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello4");
        livelloC = chipRed;
      }

      return <VaporTag variant="standard" label={livelloS} sx={livelloC} />;
    } else {
      return null;
    }
  };
  const renderLivelloRischioS = (item) => {
    if (item.effettivo !== undefined) {
      let livelloS = t("valutazione.form.rischioInerenteLivello1");

      if (item.effettivo >= 1 && item.effettivo <= 1.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello1");
      } else if (item.effettivo > 1.5 && item.effettivo <= 2.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello2");
      } else if (item.effettivo > 2.5 && item.effettivo <= 3.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello3");
      } else if (item.effettivo > 3.5) {
        livelloS = t("valutazione.form.rischioInerenteLivello4");
      }

      return livelloS;
    } else {
      return null;
    }
  };
  const renderAdeguataVerifica = (item) => {
    if (item.effettivo !== undefined) {
      let adeguataVerifica = 1;
      let adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
      let adeguataVerificaC = chipGreen;

      if (item.effettivo >= 1.0 && item.effettivo <= 1.5) {
        adeguataVerifica = 1;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
        adeguataVerificaC = chipGreen;
      } else if (item.effettivo > 1.5 && item.effettivo <= 2.5) {
        adeguataVerifica = 2;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
        adeguataVerificaC = chipYellow;
      } else if (item.effettivo > 2.5 && item.effettivo <= 3.5) {
        adeguataVerifica = 3;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello2");
        adeguataVerificaC = chipOrange;
      } else if (item.effettivo > 3.5) {
        adeguataVerifica = 4;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello3");
        adeguataVerificaC = chipRed;
      }

      return (
        <VaporTag
          variant="standard"
          label={adeguataVerificaS}
          sx={adeguataVerificaC}
        />
      );
    } else {
      return null;
    }
  };
  const renderAdeguataVerificaS = (item) => {
    if (item.effettivo !== undefined) {
      let adeguataVerifica = 1;
      let adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");

      if (item.effettivo >= 1.0 && item.effettivo <= 1.5) {
        adeguataVerifica = 1;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
      } else if (item.effettivo > 1.5 && item.effettivo <= 2.5) {
        adeguataVerifica = 2;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
      } else if (item.effettivo > 2.5 && item.effettivo <= 3.5) {
        adeguataVerifica = 3;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello2");
      } else if (item.effettivo > 3.5) {
        adeguataVerifica = 4;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello3");
      }

      return adeguataVerificaS;
    } else {
      return null;
    }
  };
  const renderDetailsButton = (userPerms, item) => {
    if (item.id !== undefined) {
      return (
        <Stack direction="row" spacing={0}>
          {userPerms.canRead ? (
            <IconButton
              color="primary"
              onClick={(e) => handleGridBtnDetailClick(e, item)}
            >
              <DetailIcon />
            </IconButton>
          ) : null}
        </Stack>
      );
    } else {
      return null;
    }
  };
  const handleGridBtnDetailClick = (event, item) => {
    if (item.fascicoloId !== undefined && item.rischioId !== undefined) {
      event.stopPropagation();
      setLocalStorageSelectedFascicolo(item.fascicoloId);
      setLocalStorageSelectedEntity("rischio", item.rischioId);
      navigate("/fascicolo/manage");
    }
  };
  return (
    <VaporPage title={t("nav.rischi")}>
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              {isDataLoaded && dataRows.length > 0 ? (
                <DataGridPro
                  rows={dataRows}
                  columns={dataColumns}
                  getRowId={(row) => row.pathId}
                  slots={{ toolbar: GridToolbar }}
                  autoHeight={true}
                  pageSizeOptions={[25, 50, 100]}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination
                  disableRowSelectionOnClick
                  treeData
                  getTreeDataPath={(row) => row.path}
                  onRowClick={(item, e) =>
                    handleGridBtnDetailClick(e, item.row)
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                        fascicoloId: false,
                      },
                    },
                    // rowGrouping: {
                    //     model: ['denominazione'],
                    // },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  groupingColDef={(params) => {
                    return {
                      headerName: "Denominazione",
                      flex: 1,
                      filterable: true,
                      sortable: true,
                      pinnable: true,
                      disableColumnMenu: false,
                      hideDescendantCount: true,
                    };
                  }}
                  slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                  }}
                />
              ) : (
                <NoData size="small" />
              )}
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
};

import { LicenseInfo } from "@mui/x-license-pro";

/**
 * OneFront SDK
 * ============
 *
 * This package provides most of the Services that you may need
 * in a OnePlatform Application.
 *
 * You write simple React Components and interact with the SDK
 * using modern hooks API.
 *
 * The SDK guides you into dividing you App into isolated Features
 * that you will glue together using ForrestJS APIs.
 */
import onefront from "@onefront/react-sdk";
//import "./i18n";

/**
 * Import the App's Features
 * =========================
 *
 * A Feature is an isolated portion of business logic that
 * interacts with the App's Services
 */

import { dashboard } from "./features/dashboard";
import { azienda } from "./features/azienda";
import { fascicolo } from "./features/fascicolo";
import { anagrafica } from "./features/anagrafica";
import { prestazione } from "./features/prestazione";
import { rischio } from "./features/rischio";
import { autovalutazione } from "./features/autovalutazione";
import { segnalazione } from "./features/segnalazione";
import { normativa } from "./features/normativa";
import { impostazione } from "./features/impostazione";

/**
 * Configure and Run the App
 * =========================
 *
 * The boot process returns a Promise that you can handle
 * to figure out whether all was good or not.
 */

const appId = process.env.REACT_APP_ID || "TS420";
// const appId = process.env.REACT_APP_ID || "demo";
const proxyBaseUrl = process.env.REACT_APP_PROXY_BASE_URL || "/api";

// MUI License Datagrid PRO
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY);

onefront
  .run({
    settings: ({ setConfig }) => {
      // general configuration
      setConfig("one.axios.proxy.baseUrl", proxyBaseUrl);
      setConfig("one.appId", appId);
      setConfig("one.auth.token.verify", true);
      setConfig("one.auth.token.refresh", true);
      setConfig("one.layout.title", "TeamSystem Antiriciclaggio");

      // i18n configuration
      setConfig("one.i18n.options.ns", ["react-sdk", "antiriciclaggio"]);
      setConfig("one.i18n.options.defaultNS", "antiriciclaggio");
      setConfig("one.i18n.options.fallbackLng", "it");
      setConfig("one.i18n.options.expirationTime", 30000);

      // disable embed mode
      setConfig("one.layout.embed.param.key", false);

      // graphql configuration
      setConfig("one.rest.graphQL.baseUrl", "aml:hasura:api://v1/graphql");

      // gainsight
      setConfig("one.gainsight.enabled", true);
    },
    features: [
      dashboard,
      azienda,
      fascicolo,
      anagrafica,
      prestazione,
      rischio,
      autovalutazione,
      segnalazione,
      normativa,
      impostazione,
    ],
  })
  .catch(console.error);
